// @flow
import React from 'react';
import dynamic from 'next/dynamic';
import Router from 'next/router';

import type { Node, } from 'react';
import type { ErrorType, } from '../../flowTypes/ErrorType';
import useWebViewChecker from '../../hooks/useWebViewChecker';

const Debug = dynamic(import('../Debug/Debug'), {
  loading: () => null,
});

function Error({ message, errorCode, path, }: ErrorType): Node {
  const isClient = typeof document !== 'undefined';
  const isWebView = useWebViewChecker();

  isClient && Router.replace({
    pathname: '/error',
    query: {
      errorCode,
      path: '/misc/error404',
      isWebView,
    },
  }, `/error?${errorCode}&path=${path}&v1`);

  return (
    <div>
      <Debug>{message}</Debug>
    </div>
  );
}

export default Error;
