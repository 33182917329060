/* eslint-disable react-hooks/exhaustive-deps */
/* global window */
// @flow
import React, { useCallback, useEffect, useState, } from 'react';
import gql from 'graphql-tag';

import { useRouter, } from 'next/router';
import Query from '../ApolloBoundary/Query';
import { doStat, } from './statutil';
import UserDispenser from '../User/UserDispenser';
import NoSSR from '../NoSSR/NoSSR';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import usePaywallType from '../../hooks/Page/usePaywallType';
import useIsBlock from '../../hooks/useIsBlock';
import useArticleType from '../../hooks/Page/useArticleType';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';
import isQueryValueTrue from '../../utils/isQueryValueTrue';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';

const GET_USER_PRODUCTS = gql`
  query getUserProducts {
    user {
      products {
        brand
        prodNum
        statusInt
        status
        isTrial
        saleCode
        promotionNum
        connectionType
      }
    }
  }
`;

const GET_USER_PLATFORM = gql`
  query GetUserPlatform {
  platform @client
}
`;

function BIRequestArticle({
  articleId,
  user,
  userProducts,
  pageType,
  authors,
  isBlock,
  platform,
}) {
  const [ previousArticleId, setPreviousArticleId, ] = React.useState(null);
  const lineage = useBreadcrumbsData();
  const { isPremiumContent, paywallType, } = usePaywallType();
  const articleType = useArticleType();
  const router = useRouter();

  const { gift, zette, } = router.query;

  const additionalData = {};

  if (gift) {
    additionalData.gift = gift;
  }

  if (zette) {
    additionalData.zette = zette;
  }

  React.useEffect(() => {
    if (articleType && lineage?.length && isPremiumContent != null && (previousArticleId !== articleId)) {
      doStat({
        user,
        userProducts,
        pageType,
        articleType,
        lineage,
        writerName: authors[0],
        writerId: authors[1],
        isArticlePage: true,
        isPremiumContent,
        isBlocked: isBlock,
        platform,
        paywallType,
        additionalData,
      });
      setPreviousArticleId(articleId);
    }
  }, [ articleId, previousArticleId, articleType, lineage, isPremiumContent, paywallType, additionalData, ]);

  return null;
}

function BIRequestSection({
  path,
  user,
  userProducts,
  platform,
  pageType,
  additionalInfo,
}) {
  const [ previousPath, setPreviousPath, ] = React.useState(null);
  const { isPremiumContent, } = usePaywallType();
  const { isBlocked, } = useIsBlock();
  const lineage = useBreadcrumbsData();

  React.useEffect(() => {
    if (previousPath !== path) {
      setPreviousPath(path);
      doStat({
        user,
        userProducts,
        pageType: pageType || 'section',
        lineage,
        isPremiumContent,
        isBlocked,
        platform,
        additionalData: additionalInfo,
      });
    }
  }, [ previousPath, path, ]);
  return null;
}

function BIRequestPromotions({
  promotionStage,
  promotionStageName,
  user,
  userProducts,
  pageType,
  platform,
}) {
  const previousStage = React.useRef(null);
  const router = useRouter();

  React.useEffect(() => {
    if (!isQueryValueTrue(router.query.cookiePlanter) && promotionStage !== previousStage.current) {
      previousStage.current = promotionStage;
      doStat({
        user,
        userProducts,
        pageType,
        platform,
        additionalData: { stage: promotionStageName, stageNumber: promotionStage, },
      });
    }
  });

  return null;
}


function BIRequestRegular({
  user,
  userProducts,
  platform,
  pageType,
  additionalInfo,
}) {
  const [ previousPath, setPreviousPath, ] = React.useState(null);
  const router = useRouter();

  const path = router.asPath;
  useEffect(() => {
    if (path !== previousPath) {
      doStat({
        user,
        userProducts,
        pageType,
        platform,
        additionalData: additionalInfo,
      });

      setPreviousPath(path);
    }
  }, [ previousPath, path, ]);

  return null;
}
type Props = {
  articleId?: ?string,
  authors?: ?string[],
  pageType?: ?string,
  promotionStage?: ?number,
  promotionStageName?: ?string,
  isBlock?: ?boolean,
  path?: ?string,
  additionalInfo: ?Object,
};
function BIRequest({
  articleId,
  authors,
  pageType,
  promotionStage,
  isBlock,
  path,
  promotionStageName,
  additionalInfo,
}: Props) {
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const onLoadElement = useCallback(e => {
    setIsLoaded(true);
  }, []);
  useDocumentEventListener('loadElement', onLoadElement, false);

  if (isWebView && !isLoaded) return null;

  return (
    <NoSSR>
      <UserDispenser
        render={({ user, }) => {
          if (!user.anonymousId) return null;
          return (
            <Query
              query={GET_USER_PLATFORM}
            >
              {({ data: PlatformData, loading, error, }) => {
                if (error) {
                  console.error('error in GET_USER_PLATFORM query', error);
                  // Should still render, even if query errors
                }
                if (loading) return null;

                const { platform, } = PlatformData || {};
                return (
                  <Query
                    query={GET_USER_PRODUCTS}
                    skip={isWebView || !user.email}
                  >
                    {({ data, loading, error, }) => {
                      if (error) {
                        console.error('error in GET_USER_PRODUCTS query', error);
                        // Should still render, even if query errors
                      }
                      if (loading) return null;
                      const userProducts = data && data.user && data.user.products;
                      if (articleId) {
                        return (
                          <BIRequestArticle
                            articleId={articleId}
                            user={user}
                            userProducts={userProducts}
                            pageType={pageType}
                            authors={authors || []}
                            isBlock={isBlock}
                            platform={platform}
                          />
                        );
                      }
                      if (pageType === 'section' || path) {
                        return (
                          <BIRequestSection
                            path={path}
                            user={user}
                            isBlock={isBlock}
                            userProducts={userProducts}
                            platform={platform}
                            pageType={pageType}
                            additionalInfo={additionalInfo}
                          />
                        );
                      }

                      if (promotionStage) {
                        return (
                          <BIRequestPromotions
                            promotionStage={promotionStage}
                            promotionStageName={promotionStageName}
                            pageType={pageType}
                            user={user}
                            userProducts={userProducts}
                            platform={platform}
                          />
                        );
                      }

                      return (
                        <BIRequestRegular
                          user={user}
                          userProducts={userProducts}
                          platform={platform}
                          pageType={pageType}
                          additionalInfo={additionalInfo}
                        />
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      />
    </NoSSR>
  );
}

BIRequest.defaultProps = {
  articleId: null,
  authors: null,
  pageType: null,
  promotionStage: null,
  promotionStageName: null,
  isBlock: null,
  path: null,
};

export default BIRequest;
