import React from 'react';
import config from 'config';
import Head from 'next/head';
import useAnalyticsBS from '../../hooks/Page/useAnalyticsBS';
import NoSSR from '../NoSSR/NoSSR';

const brightspotUrl = config.get('service.brightspot');


export function AnalyticsBSComponent() {
  const analyticsBS = useAnalyticsBS();

  if (!analyticsBS) return null;

  const {
    analyticsDataJson,
    mainContentId,
    mainContentTypeId,
    siteId,
  } = analyticsBS;

  return (
    <NoSSR>
      <Head>
        <script
          data-bsp-analytics-data={analyticsDataJson}
          data-bsp-site={siteId}
          data-bsp-contentid={mainContentId}
        />
        <script data-bsp-site={siteId} data-bsp-contentid={mainContentId} src={`${brightspotUrl}/_resource/analytics/bsp-analytics.min.js`} />

      </Head>
      <span data-bsp-pv={mainContentTypeId} />
      <span data-bsp-pv={mainContentId} />
    </NoSSR>
  );
}

export const AnalyticsBS = React.memo(AnalyticsBSComponent, () => true);
